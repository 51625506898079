import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { FeatureRmsSharedModule, RmsAuthService, RmsHeaderComponent, RmsNavbarComponent, RmsNavbarService, RmsUserVerificationService } from '@safarilaw-webapp/feature/rms/shared';
import { NavbarPermissionsService, NavbarService, SharedAppBootstrapModule } from '@safarilaw-webapp/shared/app-bootstrap';
import { AuthInterceptorService, AuthService, UserVerificationService } from '@safarilaw-webapp/shared/auth';
import { GATEWAY_TIMEOUT_URLS, SUPPORT_EMAIL, SharedCommonPagesModule } from '@safarilaw-webapp/shared/common-pages';
import { ModalDialogService } from '@safarilaw-webapp/shared/dialog';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { ErrorDialogComponent, ErrorHandlerService, HttpErrorInterceptorService } from '@safarilaw-webapp/shared/error-handling';
import { LoggerService } from '@safarilaw-webapp/shared/logging';
import { SafariDefaultReuseStategy } from '@safarilaw-webapp/shared/routing-utility';
import { IRmsUiState } from 'libs/feature/rms/ui/src/lib/redux/state.interface';
import { RmsUiReducerService } from 'libs/feature/rms/ui/src/lib/redux/state.reducer';
import { BlockUIModule } from 'ng-block-ui';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { AppUpdateSplashComponent } from './app/app-update-splash/components/app-update-splash/app-update-splash';
import { AppComponent } from './app/app.component';
import { environmentImports } from './app/app.module.env.imports';
import { AppRoutingModule } from './app/app.routing';
import { RmsConfigurationService } from './app/configuration/rms-configuration.service';
import { RmsNavbarPermissionsService } from './app/navbar/services/lpms-navbar-permissions/rms-navbar-permissions.service';
import { MaintenanceHeaderComponent } from './app/site-maintenance/components/maintenance-header/maintenance-header';
import { MaintenanceSplashComponent } from './app/site-maintenance/components/maintenance-splash/maintenance-splash';
import { environment } from './environments/environment';
import { FeatureRmsDataAccessModule, RmsDataAccessObject } from '@safarilaw-webapp/feature/rms/data-access';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<IRmsUiState>>('Rms Ui Reducers');

// DUPE: initialAppConfigFactory
// We must make sure to run this before anything else. AppConfigurationService needs to
// be constructed and any overrides need to be applied before the app starts. Since overrides use
// http to retrieve we can not do this in the constructor (constructor can't be blocked until http resolves),
// However by using initializer we can block until after overrideConfiguration finishes.
const initialAppConfigFactory =
  (appConfigService: AppConfigurationService, loggerService: LoggerService): (() => Observable<boolean>) =>
  (): Observable<boolean> =>
    appConfigService.overrideConfiguration().pipe(tap(() => loggerService.initializeElmahLogging()));
@NgModule({
  declarations: [MaintenanceSplashComponent, MaintenanceHeaderComponent, AppUpdateSplashComponent, AppComponent],
  imports: [
    AppRoutingModule,
    BlockUIModule.forRoot(),
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    FeatureRmsSharedModule,
    FeatureRmsDataAccessModule,
    BrowserModule,
    SharedCommonPagesModule,
    StoreModule.forFeature('rms', REDUCER_TOKEN),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production || environment.nonprod, registrationStrategy: 'registerImmediately' }),
    [...environmentImports]
  ],
  providers: [
    RmsUiReducerService,

    {
      provide: REDUCER_TOKEN,
      deps: [RmsUiReducerService],
      useFactory: (service: RmsUiReducerService) => service.reducers
    },
    {
      provide: GATEWAY_TIMEOUT_URLS,
      useValue: 'serveport.com / *.serveport.com'
    },
    {
      provide: SUPPORT_EMAIL,
      useValue: 'support@serveport.com'
    },

    { provide: UserVerificationService, useClass: RmsUserVerificationService },
    { provide: AuthService, useClass: RmsAuthService },
    { provide: NavbarService, useClass: RmsNavbarService },
    { provide: APP_INITIALIZER, useFactory: initialAppConfigFactory, deps: [AppConfigurationService, LoggerService], multi: true },
    {
      provide: AppConfigurationService,
      useClass: RmsConfigurationService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: NavbarPermissionsService,
      useClass: RmsNavbarPermissionsService
    },
    AppRoutingModule,
    {
      provide: RouteReuseStrategy,
      useClass: SafariDefaultReuseStategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(dialogService: ModalDialogService) {
    // Register error dialog component so we can send it via showDialog
    dialogService.registerComponent(ErrorDialogComponent.ClassId, ErrorDialogComponent);
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => {
    // lint requires promises to have catch so here it is - just rethrow...

    // Also - there used to be some weird code here that was checking if error is of
    // type boolean, before treating it like an error. That was removed but we should
    // watch if there are any weird issues.
    throw err;
  });
